<script>
import ResourceTable from "@shell/components/ResourceTable";
import ResourceFetch from "@shell/mixins/resource-fetch";

export default {
  name: "ListInfrastructureProvider",
  components: { ResourceTable },
  mixins: [ResourceFetch],
  props: {
    resource: {
      type: String,
      required: true,
    },

    schema: {
      type: Object,
      required: true,
    },

    useQueryParamsForSimpleFiltering: {
      type: Boolean,
      default: false,
    },
  },

  async fetch() {
    this.$initializeFetchData(this.resource);

    await this.$fetchType(this.resource);
  },
  computed: {
    filteredRows() {
      return this.rows.filter((el) => el._type === "InfrastructureProvider");
    },
  },
};
</script>

<template>
  <ResourceTable
    :schema="schema"
    :rows="filteredRows"
    :headers="$attrs.headers"
    :group-by="$attrs.groupBy"
    :loading="loading"
    :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
    :force-update-live-and-delayed="forceUpdateLiveAndDelayed"
  />
</template>
