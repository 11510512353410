<script>
export default {
  name: "Index",
  mounted() {
    window.location.reload();
  },
};
</script>

<template>
  <span>Something went wrong</span>
</template>

<style scoped lang="scss"></style>
