import {
  CAPI, MANAGEMENT, NORMAN, SNAPSHOT, HCI
} from '@shell/config/types';
import SteveModel from '@shell/plugins/steve/steve-class';

export default class BootsmanCluster extends SteveModel {
  get provider() {
    return this.spec?.capiConfig?.infrastructure?.provider;
  }
}
