<script>
export default {
  props: {
    cluster: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    useForIcon() {
      return !!this.cluster?.badge?.iconText;
    },
  },
};
</script>

<template>
  <div
    v-if="cluster"
    class="cluster-icon"
    :class="{ 'cluster-icon-small': small }"
  >
    <div
      v-if="useForIcon"
      class="cluster-badge-logo"
      :style="{
        backgroundColor: cluster.badge.color,
        color: cluster.badge.textColor,
      }"
    >
      {{ cluster.badge.iconText }}
    </div>
    <!-- eslint-disable -->

    <svg
      v-else-if="cluster.isLocal && !cluster.isHarvester"
      class="cluster-local-logo"
      width="27"
      height="33"
      viewBox="0 0 27 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2991 20.6778C23.2991 20.0797 22.9045 19.4815 22.109 19.2906C21.759 13.4678 19.3215 8.96875 13.4734 8.96875C7.64428 8.96875 5.20743 13.436 4.83833 19.2269C3.76288 19.2842 3.23438 19.9778 3.23438 20.6715C3.23438 21.3269 3.96 22.2178 4.84455 22.1669C5.35364 28.0087 9.12064 32.1197 13.4734 32.1197C18.4115 32.1197 21.6889 27.996 22.1152 22.1287C22.7961 21.9442 23.2991 21.2251 23.2991 20.6778Z"
        fill="white"
      />
      <path
        d="M23.2991 20.6778C23.2991 20.0797 22.9045 19.4815 22.109 19.2906C21.759 13.4678 19.3215 8.96875 13.4734 8.96875C7.64428 8.96875 5.20743 13.436 4.83833 19.2269C3.76288 19.2842 3.23438 19.9778 3.23438 20.6715C3.23438 21.3269 3.96 22.2178 4.84455 22.1669C5.35364 28.0087 9.12064 32.1197 13.4734 32.1197C18.4115 32.1197 21.6889 27.996 22.1152 22.1287C22.7961 21.9442 23.2991 21.2251 23.2991 20.6778Z"
        stroke="#0C0C0B"
        stroke-width="0.180727"
        stroke-miterlimit="10"
      />
      <path
        d="M21.9882 21.218C21.9182 20.8108 22.3322 19.8498 22.7967 20.4289C22.9113 20.6453 22.7965 20.8808 22.701 21.0653C22.6565 20.8998 22.6691 20.6962 22.5991 20.5817C22.5546 20.5498 22.5293 20.4989 22.4848 20.5498C22.2939 20.6771 22.1601 21.008 21.9882 21.218Z"
        fill="#0C0C0B"
      />
      <path
        d="M4.60834 21.1546C4.43653 20.951 4.30272 20.6201 4.11181 20.4801C4.06726 20.4292 4.04201 20.4801 3.99746 20.5119C3.92746 20.6265 3.94009 20.8301 3.89554 20.9955C3.80009 20.811 3.68529 20.5755 3.79984 20.3592C4.26438 19.7865 4.67834 20.7474 4.60834 21.1546Z"
        fill="#0C0C0B"
      />
      <path
        d="M9.41238 19.3348C9.41238 19.723 9.23442 20.0348 9.00533 20.0348C8.77624 20.0348 8.59766 19.723 8.59766 19.3348C8.59766 18.9466 8.77624 18.6348 9.00533 18.6348C9.23442 18.6348 9.41238 18.9466 9.41238 19.3348Z"
        fill="#0C0C0B"
      />
      <path
        d="M18.1438 19.3348C18.1438 19.723 17.9595 20.0348 17.7368 20.0348C17.514 20.0348 17.3359 19.723 17.3359 19.3348C17.3359 18.9466 17.514 18.6348 17.7368 18.6348C17.9595 18.6348 18.1438 18.9466 18.1438 19.3348Z"
        fill="#0C0C0B"
      />
      <path
        d="M21.1282 13.6074C20.2373 13.6074 20.5494 14.7974 20.7721 15.0201C22.0703 16.3183 19.5306 19.4047 21.7515 19.3219C21.6052 22.071 20.5492 25.7301 17.3355 23.9674C16.1137 23.2928 15.1465 23.4901 14.5228 23.8019C13.9883 24.0692 13.352 24.0056 12.8238 23.7192C11.112 22.7965 9.80083 24.5656 7.79628 24.4128C5.28901 24.2156 5.58176 21.2247 5.59449 19.3728C5.79812 19.3219 5.96365 19.1883 5.98911 18.9528C6.09093 17.9092 5.41017 17.0247 6.30108 16.1337C7.19198 15.2428 6.50466 14.3583 6.09103 13.6137C5.84921 13.1683 5.24446 14.0337 4.93264 15.4337C4.63355 16.7383 4.52522 18.6601 4.77977 19.1565C4.41704 22.1792 4.24562 28.6892 9.00562 31.6101C10.5647 32.5647 12.2385 32.8447 13.613 32.8447C18.284 32.8447 21.9679 28.7274 22.0952 24.0628L22.2356 19.0228C22.3184 18.5583 22.2864 17.5401 22.35 16.2101C22.4391 14.581 22.0191 13.6074 21.1282 13.6074Z"
        fill="#0C0C0B"
      />
      <path
        d="M10.8066 24.6992C12.3275 26.3919 13.7719 26.5447 15.6937 25.4056C15.9292 25.2592 16.1709 25.1129 16.4382 24.9792C15.0255 27.3274 11.6975 27.5947 10.8066 24.6992Z"
        fill="white"
      />
      <path
        d="M13.498 21.4611L14.5352 21.302C14.5734 21.2893 14.3891 21.512 14.3954 21.512C14.1091 20.2457 14.0073 18.9666 13.9436 17.6875C14.1218 19.0111 14.3573 20.3348 14.7646 21.5884L14.5607 21.633H14.5483L13.498 21.5693V21.4611Z"
        fill="#0C0C0B"
      />
      <path
        d="M5.70215 17.4258L5.61328 16.7258C5.6451 16.7195 8.99245 16.3122 12.3715 16.6431L12.3013 17.3431C9.01131 17.0249 5.74033 17.4258 5.70215 17.4258Z"
        fill="#0C0C0B"
      />
      <path
        d="M9.46352 20.8112C8.53443 20.8239 6.91836 20.6648 6.58745 20.5439C5.81745 20.3785 6.13506 17.3303 6.16052 17.0439L6.39605 17.0694C6.39605 17.0757 6.31339 17.8712 6.31339 18.673C6.31339 20.1112 6.57433 20.2957 6.65705 20.3212C7.32524 20.5185 10.6598 20.773 11.1371 20.3212C11.2516 20.213 11.449 19.8248 11.5317 18.4376C11.5762 17.6294 11.5572 16.8721 11.5572 16.8657L11.7927 16.8594C11.7991 17.1776 11.8624 19.9648 11.3024 20.493C11.1306 20.6394 10.679 20.8112 9.46352 20.8112Z"
        fill="#0C0C0B"
      />
      <path
        d="M21.0447 17.4961C21.0129 17.4898 17.7421 17.0952 14.4394 17.4134L14.3691 16.7134C17.7546 16.3825 21.0956 16.7898 21.1274 16.7961L21.0447 17.4961Z"
        fill="#0C0C0B"
      />
      <path
        d="M17.3098 20.882C16.4698 20.882 15.7125 20.7993 15.4579 20.5575C14.8979 20.0229 14.9618 17.2356 14.9682 16.9238L15.2037 16.9302C15.2037 16.9366 15.184 17.6938 15.2286 18.502C15.305 19.8893 15.5087 20.2775 15.6232 20.3856C16.1005 20.8375 19.4351 20.5829 20.1032 20.3856C20.5423 20.4556 20.4471 17.3947 20.358 17.1338L20.5936 17.1084C20.6127 17.4011 20.9436 20.4429 20.1672 20.6084C19.7854 20.7229 18.468 20.882 17.3098 20.882Z"
        fill="#0C0C0B"
      />
      <path
        d="M15.0757 18.3613C14.363 18.2977 11.7348 18.3104 11.7093 18.3168L11.7031 17.6104C11.8113 17.6104 14.3884 17.5977 15.1329 17.6613L15.0757 18.3613Z"
        fill="#0C0C0B"
      />
      <path
        d="M21.8482 13.4416C21.3773 11.3161 20.0092 9.43246 18.291 7.84791C16.2037 5.9261 13.3907 4.5261 10.0562 5.74155C7.68254 6.60064 8.24279 8.17246 8.24279 8.17246C8.24279 8.17246 2.99246 7.64428 5.3661 12.8243C6.92519 16.2225 13.2829 13.7152 15.6629 12.3215C17.3365 11.3352 18.7685 12.4234 20.0857 13.6261C22.0521 15.4206 22.1791 14.9434 21.8482 13.4416Z"
        fill="#0C0C0B"
      />
      <path
        d="M20.9497 4.90215C18.3406 2.31215 16.1387 1.52305 14.7196 1.26214C13.7651 1.08396 12.6836 1.16669 12.0791 1.24941C10.8255 1.4276 8.78885 2.27396 6.09703 4.90215C3.40521 7.53033 0.64327 9.96123 1.03782 11.4121C1.43236 12.8567 3.79953 13.1876 3.79953 13.1876L13.5296 13.3212L23.2596 13.1876C23.2596 13.1876 25.6274 12.8567 26.0219 11.4121C26.4037 9.96123 23.6224 7.55578 20.9497 4.90215Z"
        fill="white"
      />
      <path
        d="M20.9497 4.90215C18.3406 2.31215 16.1387 1.52305 14.7196 1.26214C13.7651 1.08396 12.6836 1.16669 12.0791 1.24941C10.8255 1.4276 8.78885 2.27396 6.09703 4.90215C3.40521 7.53033 0.64327 9.96123 1.03782 11.4121C1.43236 12.8567 3.79953 13.1876 3.79953 13.1876L13.5296 13.3212L23.2596 13.1876C23.2596 13.1876 25.6274 12.8567 26.0219 11.4121C26.4037 9.96123 23.6224 7.55578 20.9497 4.90215Z"
        stroke="#0C0C0B"
        stroke-width="0.413"
        stroke-miterlimit="10"
      />
      <path
        d="M4.0293 13.1806L4.62092 14.8925C4.62092 14.8925 8.98045 16.1716 13.5114 16.1716C18.0423 16.1716 22.4969 14.9625 22.4969 14.9625L23.0245 13.187C23.0245 13.187 19.016 10.0306 13.5623 10.1643C8.10232 10.2916 4.0293 13.1806 4.0293 13.1806Z"
        fill="white"
      />
      <path
        d="M4.0293 13.1806L4.62092 14.8925C4.62092 14.8925 8.98045 16.1716 13.5114 16.1716C18.0423 16.1716 22.4969 14.9625 22.4969 14.9625L23.0245 13.187C23.0245 13.187 19.016 10.0306 13.5623 10.1643C8.10232 10.2916 4.0293 13.1806 4.0293 13.1806Z"
        stroke="#0C0C0B"
        stroke-width="0.413"
        stroke-miterlimit="10"
      />
      <path
        d="M4.86328 14.8932C4.86328 14.8932 8.97362 16.1723 13.5045 16.1723C18.0354 16.1723 22.2483 14.9632 22.2483 14.9632C13.2501 9.24234 4.86328 14.8932 4.86328 14.8932Z"
        fill="#7371F9"
      />
      <path
        d="M4.86328 14.8932C4.86328 14.8932 8.97362 16.1723 13.5045 16.1723C18.0354 16.1723 22.2483 14.9632 22.2483 14.9632C13.2501 9.24234 4.86328 14.8932 4.86328 14.8932Z"
        stroke="#0C0C0B"
        stroke-width="0.413"
        stroke-miterlimit="10"
      />
      <path
        d="M12.6776 12.4374L12.3594 16.1411C13.0403 16.1665 14.0266 16.1856 14.6693 16.122L14.3959 12.4183C13.7022 12.3865 13.3521 12.3929 12.6776 12.4374Z"
        fill="white"
      />
      <path
        d="M12.6776 12.4374L12.3594 16.1411C13.0403 16.1665 14.0266 16.1856 14.6693 16.122L14.3959 12.4183C13.7022 12.3865 13.3521 12.3929 12.6776 12.4374Z"
        stroke="#0C0C0B"
        stroke-width="0.413"
        stroke-miterlimit="10"
      />
      <path
        d="M15.9232 5.98307C15.9232 7.30671 14.8474 8.38217 13.5238 8.38217C12.2002 8.38217 11.125 7.30671 11.125 5.98307C11.125 4.65944 12.2002 3.58398 13.5238 3.58398C14.8474 3.58398 15.9232 4.65944 15.9232 5.98307Z"
        fill="#03F68C"
      />
      <path
        d="M15.9232 5.98307C15.9232 7.30671 14.8474 8.38217 13.5238 8.38217C12.2002 8.38217 11.125 7.30671 11.125 5.98307C11.125 4.65944 12.2002 3.58398 13.5238 3.58398C14.8474 3.58398 15.9232 4.65944 15.9232 5.98307Z"
        stroke="#0C0C0B"
        stroke-width="0.413"
        stroke-miterlimit="10"
      />
    </svg>

    <!-- eslint-enable -->
    <img
      v-else-if="cluster.providerNavLogo"
      class="cluster-os-logo"
      :src="cluster.providerNavLogo"
    />
  </div>
</template>

<style lang="scss" scoped>
.rancher-icon-fill {
  fill: var(--primary);
}
.cluster-icon {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.cluster-icon-small {
  height: 25px;
  width: 25px;

  .cluster-os-logo {
    width: 25px;
    height: 25px;
  }

  .cluster-badge-logo {
    width: 25px;
    height: 25px;
  }
}

.cluster-os-logo {
  width: 32px;
  height: 32px;
}
.cluster-local-logo {
  display: flex;
  width: 25px;
}
.cluster-badge-logo {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
}
</style>
