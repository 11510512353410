import { AGE, NAME as NAME_COL, STATE } from "@shell/config/table-headers";
import { CAPI, CATALOG, NORMAN, HCI, MANAGEMENT } from "@shell/config/types";
import { MULTI_CLUSTER } from "@shell/store/features";
import { DSL } from "@shell/store/type-map";
import { BLANK_CLUSTER } from "@shell/store";

export const NAME = "bootsman";

export function init(store) {
  const {
    product,
    basicType,
    headers,
    configureType,
    virtualType,
    weightType,
    weightGroup,
    mapGroup,
  } = DSL(store, NAME);

  product({
    ifHaveType: CAPI.BOOTSMAN_CLUSTER,
    ifFeature: "bootsman-manager",
    inStore: "cluster",
    icon: "cluster-management",
    removable: false,
    showClusterSwitcher: false,
    label: "Bootsman",
    public: false,
    to: {
      name: "c-cluster-product-resource",
      params: {
        cluster: BLANK_CLUSTER,
        product: NAME,
        resource: CAPI.BOOTSMAN_CLUSTER,
      },
    },
  });

  // virtualType({
  //   name: "cloud-credentials",
  //   labelKey: "manager.cloudCredentials.label",
  //   group: "Root",
  //   namespaced: false,
  //   icon: "globe",
  //   weight: 99,
  //   route: { name: "c-cluster-manager-cloudCredential" },
  // });

  // virtualType({
  //   labelKey: "legacy.psps",
  //   name: "pod-security-policies",
  //   group: "Root",
  //   namespaced: false,
  //   weight: 5,
  //   icon: "folder",
  //   route: {
  //     name: "c-cluster-manager-pages-page",
  //     params: { cluster: "local", page: "pod-security-policies" },
  //   },
  //   exact: true,
  // });

  basicType([
    CAPI.BOOTSMAN_CLUSTER,
    // "cloud-credentials",
    // "drivers",
    // "pod-security-policies",
  ]);

  mapGroup(/^(|provisioning)\.bootsman\.tech$/, "bootsman");
  // configureType(NORMAN.CLOUD_CREDENTIAL, { showListMasthead: false, namespaced: false });
  weightType(CAPI.BOOTSMAN_CLUSTER, 100, true);
  weightType("cloud-credentials", 99, true);
  weightType("drivers", 98, true);
  weightType(CATALOG.CLUSTER_REPO, 97, true);

  // configureType(NORMAN.CLOUD_CREDENTIAL, {
  //   showState: false,
  //   showAge: false,
  //   canYaml: false,
  // });

  // virtualType({
  //   labelKey: "manager.drivers.label",
  //   name: "drivers",
  //   group: "Root",
  //   namespaced: false,
  //   icon: "globe",
  //   route: {
  //     name: "c-cluster-manager-pages-page",
  //     params: { cluster: "local", page: "rke-drivers" },
  //   },
  //   exact: true,
  // });

  // virtualType({
  //   labelKey: "manager.rkeTemplates.label",
  //   name: "rke-templates",
  //   group: "Root",
  //   namespaced: false,
  //   icon: "globe",
  //   route: {
  //     name: "c-cluster-manager-pages-page",
  //     params: { cluster: "local", page: "rke-templates" },
  //   },
  //   exact: true,
  // });

  // virtualType({
  //   labelKey: "manager.nodeTemplates.label",
  //   name: "rke-node-templates",
  //   group: "Root",
  //   namespaced: false,
  //   icon: "globe",
  //   route: {
  //     name: "c-cluster-manager-pages-page",
  //     params: { cluster: "local", page: "node-templates" },
  //   },
  //   exact: true,
  // });

  // basicType(["rke-templates", "rke-node-templates"], "RKE1Configuration");

  weightType(CAPI.MACHINE_DEPLOYMENT, 3, true);
  weightType(CAPI.MACHINE_SET, 2, true);
  weightType(CAPI.MACHINE, 1, true);
  weightType(CATALOG.CLUSTER_REPO, 0, true);
  weightType(MANAGEMENT.PSA, 5, true);

  basicType(
    [
      CAPI.MACHINE_DEPLOYMENT,
      CAPI.MACHINE_SET,
      CAPI.MACHINE,
      CATALOG.CLUSTER_REPO,
      "pod-security-policies",
      MANAGEMENT.PSA,
    ],
    "advanced"
  );

  weightGroup("advanced", -1, true);

  const MACHINE_SUMMARY = {
    name: "summary",
    labelKey: "tableHeaders.machines",
    sort: false,
    search: false,
    formatter: "MachineSummaryGraph",
    align: "center",
    width: 100,
  };

  headers(CAPI.BOOTSMAN_CLUSTER, [
    STATE,
    {
      name: "name",
      labelKey: "tableHeaders.name",
      value: "nameDisplay",
      sort: ["nameSort"],
      formatter: "ClusterLink",
      canBeVariable: true,
    },
    {
      name: "kubernetesVersion",
      label: "Kubernetes Version",
      value: "spec.capiConfig.kubernetesVersion",
      sort: "kubernetesVersion",
      search: "kubernetesVersion",
    },
    {
      name: "bootsmanVersion",
      label: "Bootsman Version",
      value: "spec.bootsmanVersion",
      sort: "bootsmanVersion",
      search: "bootsmanVersion",
    },
    {
      name: "provider",
      labelKey: "tableHeaders.provider",
      value: "spec.capiConfig.infrastructure.provider",
      sort: ["machineProvider", "provisioner"],
      // formatter: "ClusterProvider",
    },
    AGE,
    {
      name: "explorer",
      label: " ",
      align: "right",
      width: 65,
    },
  ]);

  headers(CAPI.MACHINE_DEPLOYMENT, [STATE, NAME_COL, MACHINE_SUMMARY, AGE]);
}
