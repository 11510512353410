import SteveModel from '@shell/plugins/steve/steve-class';

export default class BootsmanWorkerPool extends SteveModel {
  get clusterName() {
    return this.spec.clusterName;
  }

  scalePool(delta, save = true) {
    if (this.canUpdate && save) {
      this.spec.replicas = this.spec.replicas + delta;
      this.save();
    }
  }
}
