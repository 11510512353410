import { AGE, NAME as NAME_COL, STATE } from '@shell/config/table-headers';
import {
  CAPI, CATALOG, NORMAN, HCI, MANAGEMENT
} from '@shell/config/types';
import { MULTI_CLUSTER } from '@shell/store/features';
import { DSL } from '@shell/store/type-map';
import { BLANK_CLUSTER } from '@shell/store';
export const NAME = 'manager';

export function init(store) {
  const {
    product,
    basicType,
    headers,
    configureType,
    virtualType,
    weightType,
    weightGroup,
  } = DSL(store, NAME);

  product({
    ifHaveType:          CAPI.RANCHER_CLUSTER,
    ifFeature:           MULTI_CLUSTER,
    inStore:             'management',
    icon:                'cluster-management',
    removable:           false,
    showClusterSwitcher: false,
    to:                  {
      name:   'c-cluster-product-resource',
      params: {
        cluster:  BLANK_CLUSTER,
        product:  NAME,
        resource: CAPI.RANCHER_CLUSTER,
      },
    },
  });

  basicType([CAPI.RANCHER_CLUSTER, CAPI.BOOTSMAN_PROVIDERS]);

  configureType(CAPI.RANCHER_CLUSTER, {
    showListMasthead: false,
    namespaced:       false,
    alias:            [HCI.CLUSTER],
  });
  configureType(CAPI.BOOTSMAN_CLUSTER, {
    showListMasthead: false,
    namespaced:       false,
  });

  virtualType({
    name:       CAPI.BOOTSMAN_PROVIDERS,
    labelKey:   'product.bootsman-providers',
    group:      'Root',
    namespaced: false,
    weight:     90,
  });
  configureType(CAPI.BOOTSMAN_PROVIDERS, {
    isCreatable: false,
    isEditable:  false,
    isRemovable: false,
    showAge:     false,
    showState:   false,
    canYaml:     false,
  });
  weightType(CAPI.RANCHER_CLUSTER, 100, true);
  weightType(CATALOG.CLUSTER_REPO, 97, true);

  configureType(NORMAN.CLOUD_CREDENTIAL, {
    showState: false,
    showAge:   false,
    canYaml:   false,
  });

  weightType(CAPI.MACHINE_DEPLOYMENT, 3, true);
  weightType(CAPI.MACHINE_SET, 2, true);
  weightType(CAPI.MACHINE, 1, true);
  weightType(CATALOG.CLUSTER_REPO, 0, true);
  weightType(MANAGEMENT.PSA, 5, true);

  basicType(
    [
      CAPI.MACHINE_DEPLOYMENT,
      CAPI.MACHINE_SET,
      CAPI.MACHINE,
      CATALOG.CLUSTER_REPO,
    ],
    'advanced'
  );

  weightGroup('advanced', -1, true);

  const MACHINE_SUMMARY = {
    name:      'summary',
    labelKey:  'tableHeaders.machines',
    sort:      false,
    search:    false,
    formatter: 'MachineSummaryGraph',
    align:     'center',
    width:     100,
  };

  headers(CAPI.RANCHER_CLUSTER, [
    STATE,
    {
      name:          'name',
      labelKey:      'tableHeaders.name',
      value:         'nameDisplay',
      sort:          ['nameSort'],
      formatter:     'ClusterLink',
      canBeVariable: true,
    },
    {
      name:   'kubernetesVersion',
      label:  'Kubernetes Version',
      value:  'kubernetesVersion',
      sort:   'kubernetesVersion',
      search: 'kubernetesVersion',
    },
    {
      name:      'bootsmanVersion',
      label:     'Bootsman Version',
      value:     'nameDisplay',
      sort:      'bootsmanVersion',
      search:    'bootsmanVersion',
      formatter: 'BootsmanVersion',
    },
    {
      name:      'provider',
      labelKey:  'tableHeaders.provider',
      formatter: 'BootsmanProvider',
      sort:      false,
      search:    false,
      value:     'nameDisplay',
    },
    MACHINE_SUMMARY,
    AGE,
    {
      name:  'explorer',
      label: ' ',
      align: 'right',
      width: 65,
    },
  ]);

  headers(CAPI.MACHINE_DEPLOYMENT, [STATE, NAME_COL, MACHINE_SUMMARY, AGE]);

  // Bootsman providers

  headers(CAPI.BOOTSMAN_PROVIDERS, [
    STATE,
    {
      name:          'name',
      labelKey:      'tableHeaders.name',
      value:         'nameDisplay',
      sort:          ['nameSort'],
      formatter:     'ClusterLink',
      canBeVariable: true,
    },
    {
      name:     'version',
      labelKey: 'tableHeaders.version',
      value:    'version',
      sort:     'version',
      search:   'version',
    },
    {
      name:  'provider',
      label: 'Provider Name',
      value: 'providerName',
    },
    {
      name:  'explorer',
      label: ' ',
      align: 'right',
      width: 65,
    },
  ]);
}
