<script>
import { CAPI } from "@shell/config/types";

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
  fetch() {
    if (this.$store.getters["management/schemaFor"](CAPI.BOOTSMAN_CLUSTER)) {
      this.$store.dispatch("management/findAll", {
        type: CAPI.BOOTSMAN_CLUSTER,
      });
    }
  },
  computed: {
    bootsmanClusters() {
      return this.$store.getters["management/all"](CAPI.BOOTSMAN_CLUSTER);
    },
    currentProvider() {
      return this.getProvider(this.value);
    },
  },
  methods: {
    getProvider(id) {
      const found = this.bootsmanClusters.find((c) => c?.metadata.name === id);

      return found?.spec?.capiConfig?.infrastructure?.provider ?? "Imported";
    },
  },
};
</script>

<template>
  <span> {{ currentProvider }} </span>
</template>

<style lang="scss" scoped></style>
