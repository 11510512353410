var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"remove-modal",attrs:{"name":"promptRemove","width":400,"height":"auto","styles":"max-height: 100vh;"},on:{"closed":_vm.close}},[_c('Card',{staticClass:"prompt-remove",attrs:{"show-highlight-border":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('button',{staticClass:"btn role-secondary",on:{"click":_vm.close}},[_vm._v("\n        "+_vm._s(_vm.t("generic.cancel"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"spacer"}),_vm._v(" "),_c('AsyncButton',{staticClass:"btn bg-error ml-10",attrs:{"mode":"delete","disabled":_vm.deleteDisabled,"data-testid":_vm.componentTestid + '-confirm-button'},on:{"click":_vm.remove}})]},proxy:true}])},[_c('h4',{staticClass:"text-default-text",attrs:{"slot":"title"},slot:"title"},[_vm._v("\n      "+_vm._s(_vm.t("promptRemove.title"))+"\n    ")]),_vm._v(" "),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"mb-10"},[(!_vm.hasCustomRemove)?[_vm._v("\n          "+_vm._s(_vm.t("promptRemove.attemptingToRemove", { type: _vm.type }))+"\n          "),_c('span',{directives:[{name:"clean-html",rawName:"v-clean-html",value:(_vm.resourceNames(_vm.names, _vm.plusMore, _vm.t)),expression:"resourceNames(names, plusMore, t)"}]})]:_vm._e(),_vm._v(" "),[(_vm.hasCustomRemove)?_c(_vm.removeComponent,_vm._b({ref:"customPrompt",tag:"component",attrs:{"close":_vm.close,"needs-confirm":_vm.needsConfirm,"value":_vm.toRemove,"names":_vm.names,"type":_vm.type},on:{"errors":(e) => (_vm.error = e),"done":_vm.done},model:{value:(_vm.toRemove),callback:function ($$v) {_vm.toRemove=$$v},expression:"toRemove"}},'component',_vm._data,false)):_vm._e(),_vm._v(" "),(_vm.needsConfirm)?_c('div',{staticClass:"mt-10"},[_c('span',{directives:[{name:"clean-html",rawName:"v-clean-html",value:(
                _vm.t(
                  'promptRemove.confirmName',
                  { nameToMatch: _vm.escapeHtml(_vm.nameToMatch) },
                  true
                )
              ),expression:"\n                t(\n                  'promptRemove.confirmName',\n                  { nameToMatch: escapeHtml(nameToMatch) },\n                  true\n                )\n              "}]})]):_vm._e()]],2),_vm._v(" "),(_vm.needsConfirm)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmName),expression:"confirmName"}],attrs:{"id":"confirm","data-testid":_vm.componentTestid + '-input',"type":"text"},domProps:{"value":(_vm.confirmName)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmName=$event.target.value}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-warning mb-10 mt-10"},[_vm._v("\n        "+_vm._s(_vm.warning)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"text-error mb-10 mt-10"},[_vm._v("\n        "+_vm._s(_vm.error)+"\n      ")]),_vm._v(" "),(!_vm.needsConfirm)?_c('div',{staticClass:"text-info mt-20"},[_vm._v("\n        "+_vm._s(_vm.protip)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.chartsToRemoveIsApp)?_c('Checkbox',{staticClass:"mt-10 type",attrs:{"label-key":"promptRemoveApp.removeCrd"},on:{"input":_vm.chartAddCrdToRemove},model:{value:(_vm.chartsDeleteCrd),callback:function ($$v) {_vm.chartsDeleteCrd=$$v},expression:"chartsDeleteCrd"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }